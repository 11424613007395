import { FileAddOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Layout, message, Row, Select, TimeRangePickerProps, Tooltip } from 'antd';
import ManualPatchesTable from 'components/ManualPatchesTable';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import moment from 'moment/moment';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as AccountsSlice from 'slices/accounts';
import { useAccounts } from 'slices/accounts';
import * as CompaniesSlice from 'slices/companies';
import { useCompanies } from 'slices/companies';
import * as DriversSlices from 'slices/drivers';
import { useDrivers } from 'slices/drivers';
import { create } from 'slices/manualPatch';
import * as ManualPatchesSlice from 'slices/manualPatches';
import { useManualPatches } from 'slices/manualPatches';
import { useMyAccount } from 'slices/myAccount';
import { useAppDispatch } from 'store/store';
import { v4 } from 'uuid';
import { fuzzySelectFilter, getContainer } from '../../utils/html';

const ManualPatchesPage = () => {
  const [
    companyId,
    setCompanyId,
  ] = useState<string | null>(null);
  const [
    driverId,
    setDriverId,
  ] = useState<string | null>(null);
  const [
    userId,
    setUserId,
  ] = useState<string | null>();
  const [
    dateRange,
    setDateRange,
  ] = useState({
    from: moment().subtract(1, 'day'),
    to: moment(),
  });
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const disabledDates: TimeRangePickerProps['disabledDate'] = (current) => {
    return current && current < moment().subtract(30, 'days');
  };

  const { companiesById, companiesSubscribed, companiesLoading } = useCompanies();
  const [
    limit,
    setLimit,
  ] = useState(50);
  const { accounts, accountsSubscribed, accountsLoading } = useAccounts();
  const { patches, patchesLoading, patchesSubscribed } = useManualPatches();
  const accountsById = R.indexBy(R.prop('id'), accounts);
  const { myAccount, myAccountSubscribed, myAccountLoading } = useMyAccount();
  const { driversById, driversLoading, driversSubscribed } = useDrivers(companyId);
  const filteredManualPatches = patches.filter((patch) => {
    return (
      (!companyId || patch.companyId === companyId) &&
      (!driverId || patch.drivers.map((driver) => driver.driverId).includes(driverId))
    );
  });

  useEffect(() => {
    if (driverId && !Object.keys(driversById || {}).includes(driverId)) {
      setDriverId(null);
    }
  }, [
    driverId,
    driversById,
  ]);

  useAuthConnectionEffect(() => {
    if (myAccount?.role) {
      if (
        [
          'admin',
          'manager',
        ].includes(myAccount.role)
      ) {
        setUserId(null);
        dispatch(AccountsSlice.subscribe());
      } else {
        setUserId(myAccount.id);
      }
      return () => {
        dispatch(
          ManualPatchesSlice.unsubscribe({
            includeFinished: true,
            limit: limit,
          })
        );
        if (
          [
            'admin',
            'manager',
          ].includes(myAccount.role)
        )
          dispatch(AccountsSlice.unsubscribe());
      };
    }
  }, [
    myAccount?.role,
    limit,
  ]);

  useAuthConnectionEffect(() => {
    if (userId === undefined) {
      return;
    }

    if (patches.length > 0) {
      dispatch(ManualPatchesSlice.resetResource());
    }

    dispatch(
      ManualPatchesSlice.subscribe({
        includeFinished: true,
        limit: limit,
        lastUpdatedAt: null,
        ownerId: userId,
        from: dateRange.from,
        to: dateRange.to,
      })
    );

    return () => {
      dispatch(
        ManualPatchesSlice.unsubscribe({
          includeFinished: true,
          limit: limit,
        })
      );
    };
  }, [
    limit,
    dispatch,
    userId,
    dateRange,
  ]);

  useAuthConnectionEffect(() => {
    if (companyId) {
      dispatch(DriversSlices.subscribe(companyId));
      return () => {
        dispatch(DriversSlices.unsubscribe(companyId));
      };
    }
  }, [
    companyId,
  ]);

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  return (
    <Layout className={'accounts-list-container'}>
      <Layout.Content>
        <div className={'content'}>
          <div style={{ padding: 16 }}>
            <Row
              gutter={[
                8,
                8,
              ]}
            >
              {accountsSubscribed && (
                <Col>
                  <Tooltip getPopupContainer={getContainer} getTooltipContainer={getContainer} title="Select user">
                    <Select<string | undefined, { label: string; value: string }>
                      placeholder="Select user"
                      showSearch
                      allowClear
                      getPopupContainer={getContainer}
                      loading={accountsLoading}
                      disabled={!accountsSubscribed}
                      options={Object.values(accountsById).map((account) => ({
                        label: account.name,
                        value: account.id,
                      }))}
                      value={userId || undefined}
                      onChange={(userId) => {
                        if (userId) {
                          setUserId(userId);
                        }
                      }}
                      onClear={() => {
                        setUserId(null);
                      }}
                      filterOption={fuzzySelectFilter}
                      style={{ minWidth: 150 }}
                    />
                  </Tooltip>
                </Col>
              )}
              <Col>
                <Tooltip getPopupContainer={getContainer} getTooltipContainer={getContainer} title="Select company">
                  <Select<string, { label: string; value: string }>
                    placeholder="Select company"
                    showSearch
                    allowClear
                    getPopupContainer={getContainer}
                    loading={companiesLoading}
                    disabled={!companiesSubscribed}
                    options={Object.values(companiesById).map((company) => ({
                      label: company.name,
                      value: company._id,
                    }))}
                    value={companyId || undefined}
                    onChange={(companyId) => {
                      setCompanyId(companyId);
                      setDriverId(null);
                    }}
                    onClear={() => {
                      setDriverId(null);
                      setCompanyId(null);
                    }}
                    filterOption={fuzzySelectFilter}
                    style={{ minWidth: 150 }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip getPopupContainer={getContainer} getTooltipContainer={getContainer} title="Select driver">
                  <Select<string, { label: string; value: string }>
                    placeholder="Select driver"
                    showSearch
                    allowClear
                    getPopupContainer={getContainer}
                    loading={driversLoading}
                    disabled={!driversSubscribed}
                    options={Object.values(driversById || {}).map((driver) => ({
                      label: `${driver.firstName} ${driver.lastName}`,
                      value: driver._id,
                    }))}
                    value={driverId || undefined}
                    onChange={(driverId) => setDriverId(driverId)}
                    onClear={() => {
                      setDriverId(null);
                    }}
                    filterOption={fuzzySelectFilter}
                    style={{ minWidth: 150 }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <DatePicker.RangePicker
                  allowClear={false}
                  disabled={patchesLoading}
                  disabledDate={disabledDates}
                  value={[
                    dateRange.from,
                    dateRange.to,
                  ]}
                  format="MMM DD, YYYY"
                  onChange={(range) => {
                    if (range && range[0] && range[1]) {
                      setDateRange({
                        from: range[0],
                        to: range[1],
                      });
                    }
                  }}
                  renderExtraFooter={() => (
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>Filter the patches by creation date</div>
                  )}
                  getPopupContainer={getContainer}
                />
              </Col>
              <Col>
                <Tooltip
                  getPopupContainer={getContainer}
                  getTooltipContainer={getContainer}
                  title="Select the max number of loaded logs"
                >
                  <Select
                    disabled={patchesLoading}
                    placeholder="Limit"
                    options={[
                      50,
                      75,
                      100,
                      150,
                      200,
                      500,
                      1000,
                    ].map((count) => ({ label: count, value: count }))}
                    value={limit}
                    onChange={(count) => setLimit(count)}
                    getPopupContainer={getContainer}
                    style={{ minWidth: 100, textAlign: 'center' }}
                  />
                </Tooltip>
              </Col>
              <Col style={{ marginLeft: 'auto' }}>
                <Tooltip
                  title={
                    companyId === null
                      ? 'Force refresh the list of companies'
                      : 'Force refresh drivers of the selected company and the list of companies'
                  }
                >
                  <Button
                    icon={<SyncOutlined spin={companiesLoading || driversLoading} />}
                    disabled={companiesLoading || driversLoading}
                    onClick={() => {
                      appDispatch(CompaniesSlice.refresh());
                      if (companyId) {
                        appDispatch(DriversSlices.refresh(companyId));
                      }
                    }}
                    style={{ marginRight: '8px' }}
                  />
                </Tooltip>
                <Button
                  icon={<FileAddOutlined />}
                  key="createButton"
                  type="primary"
                  disabled={
                    ![
                      'admin',
                      'manager',
                      'shifter_hr',
                      'shifter_day',
                      'shifter_hr_day',
                      'unrestricted_shifter_hr_day',
                    ].includes(myAccount?.role || '')
                  }
                  style={{ marginLeft: 'auto' }}
                  onClick={async () => {
                    const clientResourceId = v4();
                    const { status, msg, data } = await appDispatch(
                      create({
                        clientResourceId,
                        companyId,
                        driverId,
                        type: 'days',
                      })
                    );
                    if (status === 'ok') {
                      console.log('data', data);
                      if (data?.id) {
                        window.open(`/activity/${data?.id}`, '_blank')?.focus();
                      }
                      message.success('Log has been created');
                    } else {
                      message.error(msg || 'Error');
                    }
                  }}
                >
                  CREATE
                </Button>
              </Col>
            </Row>
          </div>
          <ManualPatchesTable
            loading={patchesLoading || !patchesSubscribed || myAccountLoading || !myAccountSubscribed}
            account={myAccount || null}
            accountsById={accountsById}
            manualPatches={filteredManualPatches.map((patch) => ({ ...patch, key: patch.id }))}
          />{' '}
        </div>
      </Layout.Content>
    </Layout>
  );
};
export default ManualPatchesPage;
