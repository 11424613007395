export const timezones: {
  UTC: string;
  HAT: string;
  AKT: string;
  PT: string;
  MST: string;
  MT: string;
  CT: string;
  ET: string;
  AT: string;
  NT: string;
} = {
  UTC: 'UTC',
  HAT: 'US/Hawaii', // same as Pacific/Honolulu
  AKT: 'US/Alaska', // same as America/Anchorage
  PT: 'US/Pacific', // same as America/Los_Angeles
  MST: 'MST', // same as US/Arizona, America/Phoenix
  MT: 'US/Mountain', // same as America/Denver
  CT: 'US/Central', // same as America/Chicago
  ET: 'US/Eastern', // same as America/New_York
  AT: 'Canada/Atlantic', // same as America/Halifax
  NT: 'Canada/Newfoundland', // same as America/St_Johns
};
