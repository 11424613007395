import { message as messageApi, Modal, ModalFuncProps, notification as notificationApi } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import { Notification, NotificationWithPayload } from '../interfaces';

function useBackendNotification(
  notification?: Notification,
  defaultType?: NotificationWithPayload['type'],
  defaultTitle?: string
) {
  useEffect(() => {
    if (!notification) {
      return;
    }

    const message = typeof notification === 'string' ? notification : notification.message;
    defaultType = defaultType ?? 'info';
    defaultTitle = defaultTitle ?? `${defaultType.charAt(0).toUpperCase()}${defaultType.slice(1)}`;
    const defaultComponent = 'notification';
    const title = typeof notification === 'string' ? defaultTitle : notification.title;
    const type = typeof notification === 'string' ? defaultType : notification.type ?? defaultType;
    const component = typeof notification === 'string' ? defaultComponent : notification.component ?? defaultComponent;
    const node: ReactNode = <span dangerouslySetInnerHTML={{ __html: message }}></span>;

    const notificationHandlers = {
      message: () => messageApi.open({ content: node, type }),
      modal: () => Modal[(type as ModalFuncProps['type'])!]({ title, content: node }),
      notification: () =>
        notificationApi.open({
          message: title,
          description: node,
          type,
          duration: 0,
        }),
    };

    const handler = notificationHandlers[component] || notificationHandlers.notification;
    handler();
  }, [
    notification,
    defaultType,
    defaultTitle,
  ]);
}

export default useBackendNotification;
