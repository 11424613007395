import { AlertOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Moment } from 'moment-timezone';
import moment from 'moment/moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthConnectionEffect } from '../../hooks/useAuthConnectionEffect';
import * as CompaniesSlice from '../../slices/companies';
import { useCompanies } from '../../slices/companies';
import * as DriversSlices from '../../slices/drivers';
import { useDrivers } from '../../slices/drivers';
import { useMyAccount } from '../../slices/myAccount';
import { useAppDispatch } from '../../store/store';
import { fuzzySelectFilter, getContainer } from '../../utils/html';

export interface Violation {
  companyId: string;
  companyName: string;
  driverId: string;
  driverName: string;
  totalViolations: number;
  updatedAt: string;
  lastNotifiedAt: string | null;
}

export const ViolationsPage: FC = () => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const [
    companyId,
    setCompanyId,
  ] = useState<string | null>(null);
  const [
    driverId,
    setDriverId,
  ] = useState<string | null>(null);
  const [
    violations,
    setViolations,
  ] = useState<Violation[]>();
  const [
    filteredViolations,
    setFilteredViolations,
  ] = useState(violations);
  const [
    showModal,
    setShowModal,
  ] = useState<boolean>(false);

  const { myAccount, myAccountLoading, myAccountSubscribed } = useMyAccount();
  const { companiesById, companiesSubscribed, companiesLoading } = useCompanies();
  const { driversById, driversLoading, driversSubscribed } = useDrivers(companyId);

  type ModalFormType = { time?: Moment | null; message: string; shortcut?: string | null };
  const [form] = useForm<ModalFormType>();
  const [
    formLoading,
    setFormLoading,
  ] = useState<boolean>(false);

  useAuthConnectionEffect(() => {
    if (companyId) {
      dispatch(DriversSlices.subscribe(companyId));
      return () => {
        dispatch(DriversSlices.unsubscribe(companyId));
      };
    }
  }, [
    companyId,
  ]);

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  const fetchViolations = useCallback(async () => {
    return await appDispatch(CompaniesSlice.getViolations());
  }, [appDispatch]);

  const fetchViolationNotificationSettings = useCallback(async () => {
    return await appDispatch(CompaniesSlice.getViolationNotificationSettings());
  }, [appDispatch]);

  useAuthConnectionEffect(() => {
    fetchViolations().then((data) => {
      setViolations(data);
    });
  }, [fetchViolations]);

  useEffect(() => {
    if (violations === undefined) {
      return;
    }

    if (!companyId) {
      setFilteredViolations(violations);
      return;
    }

    if (!driverId) {
      setFilteredViolations(violations.filter((violation) => violation.companyId === companyId));
    } else {
      setFilteredViolations(
        violations.filter((violation) => violation.companyId === companyId && violation.driverId === driverId)
      );
    }
  }, [
    companyId,
    driverId,
    violations,
  ]);

  useEffect(() => {
    if (showModal) {
      setFormLoading(true);
      fetchViolationNotificationSettings().then((data) => {
        setFormLoading(false);
        form.setFieldsValue({
          time: data?.time ? moment(data.time) : null,
          message: data?.message,
          shortcut: data?.shortcut,
        });
      });
    }
  }, [showModal]);

  return (
    <Spin spinning={myAccountLoading || !myAccountSubscribed}>
      <Layout className={'accounts-list-container'}>
        <Layout.Content>
          <Row justify={'space-between'}>
            <Col span={6}>
              <Typography.Title style={{ margin: '16px 0' }} level={3}>
                VIOLATIONS
              </Typography.Title>
            </Col>
            {[
              'admin',
              'manager',
              'tech_support',
            ].includes(myAccount?.role || '') && (
              <Col
                span={3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button icon={<AlertOutlined />} key="createButton" type="primary" onClick={() => setShowModal(true)}>
                  NOTIFICATIONS
                </Button>
                <Modal
                  visible={showModal}
                  title="AUTOMATIC NOTIFICATIONS"
                  okText="CONFIRM"
                  cancelText="CANCEL"
                  onCancel={() => {
                    form.resetFields();
                    setShowModal(false);
                  }}
                  confirmLoading={formLoading}
                  onOk={() => {
                    form.submit();
                  }}
                  forceRender
                >
                  <Spin spinning={formLoading}>
                    <Form<ModalFormType>
                      form={form}
                      layout="vertical"
                      name="form_in_modal"
                      onFinish={async (values) => {
                        setFormLoading(true);
                        await appDispatch(
                          CompaniesSlice.setViolationNotifications(
                            values.time ? values.time.toDate() : null,
                            values.message,
                            values.shortcut || null
                          )
                        );
                        setFormLoading(false);
                        setShowModal(false);
                      }}
                    >
                      <Form.Item<ModalFormType> name="time" label="NOTIFICATION TIME" help="Local Time">
                        <DatePicker allowClear picker="time" format="HH:mm" />
                      </Form.Item>
                      <Form.Item<ModalFormType>
                        name="shortcut"
                        label="Template shortcut"
                        rules={[
                          {
                            max: 128,
                            message: 'The shortcut can not exceed 128 characters.',
                          },
                          {
                            pattern: /^[\w-]+$/,
                            message: 'The shortcut can only contain Latin letters, numbers, dashes and underscores.',
                          },
                        ]}
                      >
                        <Input allowClear />
                      </Form.Item>
                      <Form.Item<ModalFormType>
                        name="message"
                        label="MESSAGE"
                        required
                        rules={[
                          {
                            required: true,
                            transform: (value?: string) => (value ? value.trim() : value),
                            message:
                              'The message is required, if you want to cancel notifications just clear the time.',
                          },
                        ]}
                      >
                        <Input.TextArea />
                      </Form.Item>
                    </Form>
                  </Spin>
                </Modal>
              </Col>
            )}
          </Row>
          <div className="content">
            <div style={{ padding: 16 }}>
              <Row
                gutter={[
                  8,
                  8,
                ]}
              >
                <Col>
                  <Tooltip getPopupContainer={getContainer} getTooltipContainer={getContainer} title="Select company">
                    <Select<string, { label: string; value: string }>
                      placeholder="Select company"
                      allowClear={true}
                      showSearch
                      getPopupContainer={getContainer}
                      loading={companiesLoading}
                      disabled={!companiesSubscribed}
                      options={Object.values(companiesById).map((company) => ({
                        label: company.name,
                        value: company._id,
                      }))}
                      value={companyId || undefined}
                      onChange={(companyId) => {
                        setCompanyId(companyId);
                        setDriverId(null);
                      }}
                      filterOption={fuzzySelectFilter}
                      style={{ minWidth: 150 }}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip getPopupContainer={getContainer} getTooltipContainer={getContainer} title="Select driver">
                    <Select<string, { label: string; value: string }>
                      placeholder="Select driver"
                      allowClear={true}
                      showSearch
                      getPopupContainer={getContainer}
                      loading={driversLoading}
                      disabled={!driversSubscribed}
                      options={Object.values(driversById || {}).map((driver) => ({
                        label: `${driver.firstName} ${driver.lastName}`,
                        value: driver._id,
                      }))}
                      value={driverId || undefined}
                      onChange={(driverId) => setDriverId(driverId)}
                      filterOption={fuzzySelectFilter}
                      style={{ minWidth: 150 }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </div>
            <Table<Violation>
              rowKey={(record) => `${record.companyId}-${record.driverId}`}
              dataSource={filteredViolations}
              loading={filteredViolations === undefined}
            >
              <Table.Column<Violation> title="Company Name" key="companyId" dataIndex="companyName" />
              <Table.Column<Violation> title="Driver Name" key="driverId" dataIndex="driverName" />
              <Table.Column<Violation> title="Violations" key="total_violations" dataIndex="totalViolations" />
              <Table.Column<Violation>
                title={
                  <Tooltip title="The date and time when the violations were last successfully fetched and updated.">
                    Updated At
                  </Tooltip>
                }
                key="updated_at"
                dataIndex="updatedAt"
                render={(date: string) => moment(date).format('YYYY-MM-DD HH:mm:ss')}
                sorter={(a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt)}
              />
              <Table.Column<Violation>
                title={
                  <Tooltip title="The date and time when the notification about violations was last sent.">
                    Notified At
                  </Tooltip>
                }
                key="notified_at"
                dataIndex="lastNotifiedAt"
                render={(date: string | null) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null)}
                sorter={(a, b) =>
                  (b.lastNotifiedAt ? Date.parse(b.lastNotifiedAt) : 3e12) -
                  (a.lastNotifiedAt ? Date.parse(a.lastNotifiedAt) : 3e12)
                }
              />
            </Table>
          </div>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};
