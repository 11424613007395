import { StopOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Select, Table, Tooltip, Typography } from 'antd';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { BackgroundJobPayload, getJobLabel, JobName, JobState, JobStateMapping } from 'interfaces';
import moment from 'moment/moment';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as BackgroundJobsSlice from 'slices/backgroundJobs';
import { useBackgroundJobs } from 'slices/backgroundJobs';
import { getContainer } from 'utils/html';
import CreateBackgroundJobModal from './create';

const isJobStoppable = (job: BackgroundJobPayload) =>
  job.queue !== 'patch-queue' && job.state === 'active' && job.payload?.jobToken !== undefined && !job.stoppedAt;

const BackgroundJobsPage = () => {
  const [
    showModal,
    setShowModal,
  ] = useState<boolean>(false);
  const [
    limit,
    setLimit,
  ] = useState(200);

  const { jobs, jobsLoading, jobsSubscribed } = useBackgroundJobs();
  const dispatch = useDispatch();

  useAuthConnectionEffect(() => {
    dispatch(BackgroundJobsSlice.subscribe({ limit }));
    return () => {
      dispatch(BackgroundJobsSlice.unsubscribe());
    };
  }, [limit]);

  const isLoading = !jobsSubscribed || jobsLoading;

  const jobNamesAndLabels = useMemo(
    () => [...new Set(jobs.map((job) => job.name))].map((name) => ({ label: getJobLabel(name), name })),
    [jobs]
  );
  const queueNames = useMemo(() => [...new Set(jobs.map((job) => job.queue))], [jobs]);
  const jobStates = [
    ...Object.values(JobStateMapping),
    'stopped',
  ];

  return (
    <Layout className={'accounts-list-container'}>
      <Layout.Content>
        <Row>
          <Col span={3}>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              JOBS
            </Typography.Title>
          </Col>
        </Row>
        <Row justify={'space-between'} style={{ margin: '1rem 0' }}>
          <Col span={21}>
            <Tooltip
              getPopupContainer={getContainer}
              getTooltipContainer={getContainer}
              title="Select the max number of loaded jobs"
            >
              <Select
                disabled={jobsLoading}
                placeholder="Limit"
                options={[
                  200,
                  500,
                  1000,
                  5000,
                  10000,
                  null,
                ].map((count) => ({ label: count ?? 'ALL', value: count }))}
                value={limit}
                onChange={(count) => setLimit(count)}
                getPopupContainer={getContainer}
                style={{ minWidth: 120, textAlign: 'center' }}
              />
            </Tooltip>
          </Col>
          <Col
            span={3}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button key="createButton" type="primary" onClick={() => setShowModal(true)}>
              CREATE
            </Button>
            <CreateBackgroundJobModal isVisible={showModal} onCancel={() => setShowModal(false)} />
          </Col>
        </Row>
        <div className={'content'}>
          <Table<BackgroundJobPayload> loading={isLoading} dataSource={jobs} rowKey={(job) => job.id}>
            <Table.Column<BackgroundJobPayload>
              title="NAME"
              dataIndex="name"
              key="name"
              filters={jobNamesAndLabels.map((item) => ({
                value: item.name,
                text: item.label,
              }))}
              onFilter={(value, record) => typeof value === 'string' && record.name.indexOf(value) === 0}
              render={(name: JobName) => getJobLabel(name)}
            />
            \
            <Table.Column<BackgroundJobPayload>
              title="LOG ID"
              dataIndex="payload"
              key="manualPatchId"
              render={(payload?: any) =>
                payload?.manualPatchId ? (
                  <a href={`/activity/${payload.manualPatchId}`}>{payload.manualPatchId}</a>
                ) : (
                  '-'
                )
              }
            />
            <Table.Column<BackgroundJobPayload>
              title="COMPANY"
              dataIndex="payload"
              key="company"
              render={(payload?: any) => payload?.companyName || '-'}
            />
            <Table.Column<BackgroundJobPayload>
              title="DRIVER"
              dataIndex="payload"
              key="driver"
              render={(payload?: any) => payload?.driverName || '-'}
            />
            <Table.Column<BackgroundJobPayload>
              title="FROM"
              dataIndex="payload"
              key="from"
              render={(payload?: any) => (payload?.from ? moment(payload.from).format('YYYY-MM-DD') : '-')}
            />
            <Table.Column<BackgroundJobPayload>
              title="TO"
              dataIndex="payload"
              key="to"
              render={(payload?: any) => (payload?.to ? moment(payload.to).format('YYYY-MM-DD') : '-')}
            />
            <Table.Column<BackgroundJobPayload>
              title="QUEUE"
              dataIndex="queue"
              key="queue"
              filters={queueNames.map((name) => ({
                value: name,
                text: name,
              }))}
              onFilter={(value, record) => typeof value === 'string' && record.queue.indexOf(value) === 0}
            />
            <Table.Column<BackgroundJobPayload>
              title="STATE"
              dataIndex="state"
              key="state"
              render={(state: JobState, record: BackgroundJobPayload) => {
                return isJobStoppable(record) ? (
                  <Tooltip title="The job is active. Attempt to stop it?">
                    <Button
                      onClick={() => dispatch(BackgroundJobsSlice.stopBackgroundJob(record.payload.jobToken))}
                      shape="circle"
                      icon={<StopOutlined />}
                      style={{ color: 'red' }}
                    />
                  </Tooltip>
                ) : record.stoppedAt ? (
                  'stopped'
                ) : (
                  JobStateMapping[state]
                );
              }}
              filters={jobStates.map((name) => ({
                value: name,
                text: name,
              }))}
              onFilter={(value, record) => {
                if (value === 'stopped') {
                  return record.stoppedAt !== null;
                }

                const key = Object.keys(JobStateMapping).find(
                  (key) => JobStateMapping[key as keyof typeof JobStateMapping] === value
                );
                return typeof value === 'string' && key !== undefined && record.state.indexOf(key) === 0;
              }}
            />
            <Table.Column<BackgroundJobPayload>
              title="SCHEDULED"
              dataIndex="scheduledAt"
              key="scheduledAt"
              render={(date?: string) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-')}
              sorter={(a, b, sortOrder) =>
                moment(a.scheduledAt || moment().add(sortOrder === 'ascend' ? 10 : -10, 'year')).diff(
                  b.scheduledAt || moment().add(sortOrder === 'ascend' ? 10 : -10, 'year')
                )
              }
            />
            <Table.Column<BackgroundJobPayload>
              title="FINISHED AT"
              dataIndex="finishedAt"
              key="finishedAt"
              render={(date?: string) => (date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-')}
              sorter={(a, b, sortOrder) =>
                moment(a.finishedAt || moment().add(sortOrder === 'ascend' ? 10 : -10, 'year')).diff(
                  b.finishedAt || moment().add(sortOrder === 'ascend' ? 10 : -10, 'year')
                )
              }
            />
          </Table>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default BackgroundJobsPage;
