import * as d3 from 'd3';
import { PieArcDatum } from 'd3';
import moment from 'moment';
import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import { useD3 } from '../hooks/useD3';

interface OwnProps {
  name: string;
  value?: number;
  total?: number;
  color: string;
}

const DonutProgressChart: FC<OwnProps> = ({ name, value, total, color }) => {
  const width = 120;
  const height = 120;
  const radius = Math.min(width, height) / 2;

  const chart = useD3((svg) => {
    svg
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [
        -width / 2,
        -height / 2,
        width,
        height,
      ])
      .attr('style', 'max-width: 100%; height: auto; max-height: 100%');

    svg.append('g').attr('class', 'data');
    svg
      .append('g')
      .attr('class', 'legend')
      .append('text')
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'middle');

    return () => {
      svg.selectAll('*').remove();
    };
  }, []);

  useLayoutEffect(() => {
    const svg = d3.select(chart.current);

    const arc = d3
      .arc<PieArcDatum<{ value: number; color: string }>>()
      .innerRadius(radius * 0.8)
      .outerRadius(radius - 1);

    const pie = d3
      .pie<{ value: number; color: string }>()
      .padAngle(1 / radius)
      .sort(null)
      .value((d) => d.value ?? 0);

    svg
      .select('.data')
      .selectAll('.arc')
      .data(
        pie([
          { value: total !== undefined && value !== undefined ? total - value : 1, color: '#ddd' },
          { value: value ?? 0, color },
        ])
      )
      .join('path')
      .attr('fill', (d) => d.data.color)
      .attr('d', arc)
      .attr('class', 'arc');

    const duration = moment
      .duration({
        millisecond: value,
      })
      .asMinutes();
    const hours = Math.floor(duration / 60);
    const minutes = Math.floor(duration % 60);

    svg
      .select('.legend > text')
      .selectAll('.time')
      .join('tspan.time')
      .data<string>([
        value !== undefined ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}` : '-- : --',
      ])
      .join('tspan')
      .text((d) => d)
      .attr('x', 0)
      .attr('dy', '-.15em')
      .style('fill', color)
      .style('font-size', '28px')
      .attr('class', 'time');

    svg
      .select('.legend > text')
      .selectAll('.label')
      .join('tspan.label')
      .data<string>([name])
      .join('tspan')
      .text((d) => d)
      .attr('x', 0)
      .attr('dy', '1.1em')
      .style('fill', '#aaa')
      .style('font-size', '20px')
      .attr('class', 'label');
  }, [
    chart,
    radius,
    value,
    total,
    color,
    name,
  ]);

  return <svg ref={chart} />;
};

export default DonutProgressChart;
