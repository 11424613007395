import { ApiOutlined, SyncOutlined, WifiOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { CSSProperties, FC, HTMLAttributes } from 'react';
import { DriverStatus } from '../interfaces';
import DonutProgressChart from './DonutProgressChart';

interface OwnProps extends HTMLAttributes<HTMLDivElement> {
  status: DriverStatus;
  onReloadData: () => void;
  isLoading: boolean;
}

const getRegulationTimeLeft = (status?: { accumulatedTime: number; limitTime: number }) => {
  if (!status) {
    return undefined;
  }

  if (status.accumulatedTime < 0) {
    return status.limitTime;
  }

  return Math.max(status.limitTime - status.accumulatedTime, 0);
};

const DriverStatusChart: FC<OwnProps> = ({ status, isLoading, onReloadData, ...otherProps }) => {
  const renderConnectionIcon = () => {
    const defaultStyles: CSSProperties = { width: '24px', height: '24px' };

    switch (status.eldStatus) {
      case 'offline':
        return <ApiOutlined style={defaultStyles} title="Offline" />;
      case 'connected':
        return <WifiOutlined style={{ ...defaultStyles, color: 'green', fontSize: '1.1rem' }} title="Connected" />;
      default:
        return <WifiOutlined style={{ ...defaultStyles, color: 'red', fontSize: '1.1rem' }} title="Disconnected" />;
    }
  };

  const renderCharts = () => {
    const statuses = [
      { name: 'Break', time: status.breakTime, color: '#edb540' },
      { name: 'Drive', time: status.driveTime, color: '#499c5b' },
      { name: 'Shift', time: status.shiftTime, color: '#7653df' },
      { name: 'Cycle', time: status.cycleTime, color: '#de6f2d' },
    ];

    return statuses.map(({ name, time, color }) => (
      <DonutProgressChart
        key={name}
        name={name}
        value={getRegulationTimeLeft(time)}
        total={time?.limitTime}
        color={color}
      />
    ));
  };

  return (
    <div
      style={{
        display: 'grid',
        gap: '1em',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        height: '58px',
        paddingRight: '30px',
      }}
      {...otherProps}
    >
      {renderCharts()}
      <Space direction="vertical" size="small" style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Tooltip title="Refresh HoS">
          <Button size="small" type="link" onClick={() => onReloadData()} icon={<SyncOutlined spin={isLoading} />} />
        </Tooltip>
        {renderConnectionIcon()}
      </Space>
    </div>
  );
};

export default DriverStatusChart;
