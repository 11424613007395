import { SnippetsOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  type SelectProps,
} from 'antd';
import moment from 'moment-timezone';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { useAuthConnectionEffect } from '../hooks/useAuthConnectionEffect';
import { LogEvent } from '../interfaces';
import * as VehiclesSlice from '../slices/vehicles';
import * as VehiclesSlices from '../slices/vehicles';
import { useVehicles } from '../slices/vehicles';
import { timezones } from '../utils/timezone';
import { eventLabels, originLabels } from '../views/manual-patch/details';

const { Option } = Select;

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

interface Values {
  id: number;
  time: moment.Moment;
  type: string;
  certificationDate?: moment.Moment;
  origin: string;
  odometer: string;
  engineHours: string;
  locationSource: string;
  positioning: string;
  latitude: string;
  longitude: string;
  notes: string[];
  vehicleId: string;
}

type EventUpdateFormProps = {
  isVisible: boolean;
  onConfirm: (event: LogEvent) => void;
  onCancel: () => void;
  event: LogEvent & { odometerOffset?: number };
};

type Timezone = keyof typeof timezones;

const convertToEventTime = (datetime: moment.Moment, timezone: Timezone) => {
  return datetime.isUtcOffset() ? datetime : datetime.tz(timezones[timezone], true);
};

const EventUpdateForm: React.FC<EventUpdateFormProps> = ({ isVisible, onConfirm, onCancel, event }) => {
  const { vehiclesById, vehiclesLoading, vehiclesSubscribed } = useVehicles(event.companyId);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [form] = Form.useForm<Values>();
  const [
    isCertificationEvent,
    setIsCertificationEvent,
  ] = useState(event.eventCode.id.startsWith('DR_CERT'));
  const [
    isAuthenticationEvent,
    setIsAuthenticationEvent,
  ] = useState(false);
  const [
    eventType,
    setEventType,
  ] = useState(event.eventCode.id);
  const [
    eventDate,
    setEventDate,
  ] = useState(
    moment.tz(event.eventTime.timestamp, timezones[event.eventTime.logDate.timeZone.id] || 'America/Los_Angeles')
  );
  const [
    isUpdatingTimeAllowed,
    setIsUpdatingTimeAllowed,
  ] = useState(true);
  const [
    isUpdatingTypeAllowed,
    setIsUpdatingTypeAllowed,
  ] = useState(true);
  const [
    isUpdatingCertificationDateAllowed,
    setIsUpdatingCertificationDateAllowed,
  ] = useState(false);
  const [
    isUpdatingOriginAllowed,
    setIsUpdatingOriginAllowed,
  ] = useState(true);
  const [
    isUpdatingVehicleAllowed,
    setIsUpdatingVehicleAllowed,
  ] = useState(true);
  const [
    isUpdatingOdometerAllowed,
    setIsUpdatingOdometerAllowed,
  ] = useState(true);
  const [
    isUpdatingEngineHoursAllowed,
    setIsUpdatingEngineHoursAllowed,
  ] = useState(true);
  const [
    isUpdatingLatitudeAllowed,
    setIsUpdatingLatitudeAllowed,
  ] = useState(true);
  const [
    isUpdatingLongitudeAllowed,
    setIsUpdatingLongitudeAllowed,
  ] = useState(true);
  const [
    isUpdatingNotesAllowed,
    setIsUpdatingNotesAllowed,
  ] = useState(true);

  const [
    noteOptions,
    setNoteOptions,
  ] = useState<SelectProps['options']>([]);

  useAuthConnectionEffect(() => {
    if (event.companyId) {
      dispatch(VehiclesSlices.subscribe(event.companyId));
      return () => {
        dispatch(VehiclesSlices.unsubscribe(event.companyId));
      };
    }
  }, [event.companyId]);

  const onTypeChange = (value: string) => {
    setEventType(value);
  };

  useEffect(() => {
    setIsUpdatingTimeAllowed(true);
    setIsUpdatingTypeAllowed(true);
    setIsUpdatingCertificationDateAllowed(false);
    setIsUpdatingOriginAllowed(true);
    setIsUpdatingVehicleAllowed(true);
    setIsUpdatingOdometerAllowed(true);
    setIsUpdatingEngineHoursAllowed(true);
    setIsUpdatingLatitudeAllowed(true);
    setIsUpdatingLongitudeAllowed(true);
    setIsUpdatingNotesAllowed(true);
    setIsCertificationEvent(false);
    setIsAuthenticationEvent(false);

    if (eventType === 'DS_ON') {
      setNoteOptions([
        { label: 'PTI', value: 'PTI' },
        { label: 'Pickup', value: 'Pickup' },
        { label: 'Delivery', value: 'Delivery' },
        { label: 'Drop', value: 'Drop' },
        { label: 'Hook', value: 'Hook' },
        { label: 'Fuel', value: 'Fuel' },
      ]);
    } else if (eventType === 'DS_OFF') {
      setNoteOptions([
        { label: 'Break', value: 'Break' },
      ]);
    } else {
      setNoteOptions([]);
    }

    switch (eventType) {
      case 'DIAG_LOGGED':
      case 'DIAG_CLEARED':
        setIsUpdatingTypeAllowed(false);
        setIsUpdatingOriginAllowed(false);
        setIsUpdatingVehicleAllowed(false);
        setIsUpdatingNotesAllowed(false);
        break;
      case 'DR_CERT_1':
      case 'DR_CERT_2':
      case 'DR_CERT_3':
      case 'DR_CERT_4':
      case 'DR_CERT_5':
      case 'DR_CERT_6':
      case 'DR_CERT_7':
      case 'DR_CERT_8':
      case 'DR_CERT_9':
        setIsUpdatingCertificationDateAllowed(true);
        setIsUpdatingOriginAllowed(false);
        setIsUpdatingOdometerAllowed(false);
        setIsUpdatingEngineHoursAllowed(false);
        setIsUpdatingLatitudeAllowed(false);
        setIsUpdatingLongitudeAllowed(false);
        setIsCertificationEvent(true);
        if (event.certifiedRecordLogDate) {
          form.setFieldsValue({
            certificationDate: moment(event.certifiedRecordLogDate.date),
          });
        } else {
          form.setFieldsValue({
            certificationDate: eventDate,
          });
        }
        break;
      case 'DR_LOGIN':
      case 'DR_LOGOUT':
        setIsAuthenticationEvent(true);
        break;
      case 'LOG_NORMAL_PRECISION':
      case 'ENG_DOWN_NORMAL':
      case 'ENG_UP_NORMAL':
        form.setFields([
          {
            name: 'origin',
            value: 'ELD',
            errors: [],
          },
        ]);
        break;
      case 'DS_OFF':
      case 'DS_SB':
      case 'DR_IND_PC':
      case 'DR_IND_YM':
        form.setFields([
          {
            name: 'origin',
            value: 'DRIVER',
            errors: [],
          },
        ]);
        break;
      default:
        setIsUpdatingTimeAllowed(true);
        setIsUpdatingTypeAllowed(true);
        setIsUpdatingOriginAllowed(true);
        setIsUpdatingVehicleAllowed(true);
        setIsUpdatingOdometerAllowed(true);
        setIsUpdatingEngineHoursAllowed(true);
        setIsUpdatingLatitudeAllowed(true);
        setIsUpdatingLongitudeAllowed(true);
        setIsUpdatingNotesAllowed(true);
    }

    if (!eventType.startsWith('DR_CERT')) {
      form.setFieldsValue({
        certificationDate: undefined,
      });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventType,
    event.certifiedRecordLogDate,
    eventDate,
  ]);

  useEffect(() => {
    if (!vehiclesSubscribed) {
      return;
    }

    setEventType(event.eventCode.id);
    form.setFieldsValue({
      id: event.seqId ? parseInt(event.seqId, 16) : undefined,
      time: moment.tz(
        event.eventTime.timestamp,
        timezones[event.eventTime.logDate.timeZone.id] || 'America/Los_Angeles'
      ),
      type: event.eventCode.id,
      certificationDate:
        event.eventCode.id.startsWith('DR_CERT') && event.certifiedRecordLogDate
          ? moment(event.certifiedRecordLogDate.date)
          : undefined,
      origin: event.recordOrigin?.id || 'DRIVER',
      odometer: event.totalVehicleMiles
        ? (event.totalVehicleMiles + (event.odometerOffset || 0)).toString()
        : undefined,
      engineHours: event.totalEngineHours?.toString(),
      locationSource:
        event.isLive === undefined
          ? 'Not Selected'
          : event.isLive
            ? 'Location generated when connected to ECM'
            : 'Location when not connected to ECM',
      positioning:
        event.driverLocationDescription !== undefined
          ? 'Manual'
          : event.location !== undefined && event.location.calculatedLocation !== undefined
            ? 'Automatic'
            : 'Not Selected',
      latitude: event.location?.lat?.toString(),
      longitude: event.location?.lon?.toString(),
      notes: event.eventComment?.split(', ') || [],
      vehicleId: Object.values(vehiclesById || {})
        .filter((vehicle) => vehicle.active)
        .find((vehicle) => vehicle.name === event.vehicle.name)?._id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    event,
    form,
    vehiclesSubscribed,
  ]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values: Values) => {
        form.resetFields();
        const eventTimezone = (event && event.eventTime.logDate.timeZone.id) || 'PT';
        const certificationTimezone = (event && event.certifiedRecordLogDate?.timeZone.id) || eventTimezone;
        const updatedMomentTime = convertToEventTime(values.time, eventTimezone);
        const updatedEvent = R.mergeDeepRight<LogEvent, DeepPartial<LogEvent>>(event!, {
          eventTime: isUpdatingTimeAllowed
            ? {
                // https://github.com/ant-design/ant-design/issues/11012#issuecomment-1177527182
                timestamp: updatedMomentTime.valueOf(),
                logDate: {
                  ...event?.eventTime.logDate,
                  date: updatedMomentTime.format('yyyy/MM/DD'),
                },
              }
            : event.eventTime,
          certifiedRecordLogDate:
            values.type.startsWith('DR_CERT') && values.certificationDate
              ? {
                  date: convertToEventTime(values.certificationDate, certificationTimezone).format('yyyy/MM/DD'),
                  timeZone: {
                    id: certificationTimezone,
                  },
                  logStartOffset: event.eventTime.logDate.logStartOffset,
                }
              : undefined,
          eventCode: isUpdatingTypeAllowed
            ? {
                id: values.type,
              }
            : event.eventCode,
          recordOrigin: isUpdatingOriginAllowed
            ? {
                id: values.origin,
              }
            : isCertificationEvent
              ? undefined
              : event.recordOrigin,
          totalVehicleMiles: isUpdatingOdometerAllowed
            ? values.odometer
              ? +values.odometer - (event?.odometerOffset || 0)
              : undefined
            : isCertificationEvent
              ? undefined
              : event.totalVehicleMiles,
          totalEngineHours: isUpdatingEngineHoursAllowed
            ? values.engineHours
              ? +values.engineHours
              : undefined
            : isCertificationEvent
              ? undefined
              : event.totalEngineHours,
          location:
            isUpdatingLatitudeAllowed || isUpdatingLongitudeAllowed
              ? {
                  lat: isUpdatingLatitudeAllowed
                    ? values.latitude
                      ? +values.latitude
                      : undefined
                    : event.location?.lat,
                  lon: isUpdatingLongitudeAllowed
                    ? values.longitude
                      ? +values.longitude
                      : undefined
                    : event.location?.lon,
                }
              : isCertificationEvent
                ? undefined
                : event.location,
          eventComment: isUpdatingNotesAllowed
            ? values.notes.length > 0
              ? values.notes.join(', ')
              : undefined
            : event.eventComment,
          seqId: Number(values.id).toString(16),
        });

        if (isUpdatingVehicleAllowed && values.vehicleId && vehiclesById) {
          const vehicle = vehiclesById[values.vehicleId];
          updatedEvent.vehicle = {
            name: vehicle.name,
            id: vehicle._id,
            vin: vehicle.vin,
          };
        }

        onConfirm(updatedEvent as LogEvent);
      })
      .catch(() => {
        // Validation failed
      });
  };

  return (
    <Modal
      visible={isVisible}
      title={
        !!event.i ? (
          <>
            <p>Edit event</p>
            <small>
              <span role="img" aria-label="Warning">
                ⚠️
              </span>{' '}
              Warning{' '}
              <span role="img" aria-label="Warning">
                ⚠️
              </span>{' '}
              Usually downloaded events cannot be edited for 8 days
            </small>
          </>
        ) : (
          'Edit event'
        )
      }
      onCancel={onCancel}
      onOk={handleSubmit}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
        name="event_update_form"
      >
        <Form.Item
          name="id"
          label="ID"
          rules={[
            {
              required: true,
              pattern: new RegExp('^[0-9]+$'),
              message: 'ID can be only positive number',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="time" label="Time">
          <DatePicker
            showTime
            style={{ width: '100%' }}
            format="MMM DD, YYYY HH:mm:ss"
            onChange={(datetime) => {
              if (!datetime) {
                return;
              }
              setEventDate(datetime);
            }}
            disabled={!isUpdatingTimeAllowed}
          />
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Select onChange={onTypeChange} disabled={!isUpdatingTypeAllowed}>
            {Object.keys(eventLabels).map((key) => (
              <Option value={key} key={key}>
                {eventLabels[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isUpdatingCertificationDateAllowed ? (
          <Form.Item name="certificationDate" label="Date to Certify">
            <DatePicker
              disabled={!isUpdatingCertificationDateAllowed}
              allowClear={false}
              style={{ width: '100%' }}
              format="MMM DD, YYYY"
            />
          </Form.Item>
        ) : null}
        {!isCertificationEvent ? (
          <Form.Item
            name="origin"
            label="Origin"
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value !== 'DRIVER' &&
                    [
                      'DS_OFF',
                      'DS_SB',
                      'DR_IND_PC',
                      'DR_IND_YM',
                    ].includes(getFieldValue('type'))
                  ) {
                    return Promise.reject(new Error("Origin must be 'Driver' for the set event type."));
                  }

                  if (
                    value !== 'ELD' &&
                    [
                      'LOG_NORMAL_PRECISION',
                      'ENG_DOWN_NORMAL',
                      'ENG_UP_NORMAL',
                    ].includes(getFieldValue('type'))
                  ) {
                    return Promise.reject(new Error("Origin must be 'Auto' for the set event type."));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select disabled={!isUpdatingOriginAllowed}>
              {Object.keys(originLabels).map((key) => (
                <Option value={key} key={key}>
                  {originLabels[key]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item label="Vehicle" htmlFor="vehicleId">
          <div style={{ display: 'flex' }}>
            <Form.Item name="vehicleId" noStyle>
              <Select
                loading={vehiclesLoading}
                disabled={!vehiclesSubscribed || !isUpdatingVehicleAllowed}
                placeholder={'No Vehicle'}
              >
                {Object.values(vehiclesById || {})
                  .filter((vehicle) => vehicle.active)
                  .map((vehicle) => (
                    <Select.Option key={vehicle._id} value={vehicle._id}>
                      {vehicle.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Popconfirm
              title="Are you sure you want to force refresh vehicles of the company?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                appDispatch(VehiclesSlice.refresh(event.companyId));
              }}
            >
              <Button
                icon={<SyncOutlined spin={vehiclesLoading} />}
                disabled={vehiclesLoading}
                style={{ marginLeft: '8px' }}
              />
            </Popconfirm>
          </div>
        </Form.Item>
        {!isCertificationEvent ? (
          <Form.Item name="odometer" label="Odometer" rules={[{ required: !isCertificationEvent }]}>
            <InputNumber disabled={!isUpdatingOdometerAllowed} min="0" precision={0} style={{ width: '100%' }} />
          </Form.Item>
        ) : null}
        {!isCertificationEvent ? (
          <Form.Item name="engineHours" label="Engine Hours" rules={[{ required: !isCertificationEvent }]}>
            <InputNumber
              disabled={!isUpdatingEngineHoursAllowed}
              min="0"
              step="0.1"
              stringMode
              style={{ width: '100%' }}
            />
          </Form.Item>
        ) : null}
        <Form.Item name="locationSource" label="Location Source">
          <Input value="Location generated when connected to ECM" disabled={true} />
        </Form.Item>
        <Form.Item name="positioning" label="Positioning">
          <Input value="Automatic" disabled={true} />
        </Form.Item>
        {!isCertificationEvent ? (
          <Form.Item
            name="latitude"
            label="Latitude"
            rules={[{ required: !isCertificationEvent && !isAuthenticationEvent }]}
          >
            <InputNumber
              disabled={!isUpdatingLatitudeAllowed}
              min="-90"
              max="90"
              step="0.000001"
              stringMode
              style={{ width: '100%' }}
            />
          </Form.Item>
        ) : null}
        {!isCertificationEvent ? (
          <Form.Item
            name="longitude"
            label="Longitude"
            rules={[{ required: !isCertificationEvent && !isAuthenticationEvent }]}
          >
            <InputNumber
              disabled={!isUpdatingLongitudeAllowed}
              min="-180"
              max="180"
              step="0.000001"
              stringMode
              style={{ width: '100%' }}
            />
          </Form.Item>
        ) : null}
        {!isCertificationEvent && isUpdatingLatitudeAllowed && isUpdatingLongitudeAllowed ? (
          <Row style={{ marginTop: '-12px', marginBottom: '24px' }}>
            <Col span={16} offset={6}>
              <Button
                type="default"
                icon={<SnippetsOutlined />}
                onClick={async () => {
                  try {
                    const coords =
                      /(?<lat>^[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?))\s*,\s*(?<lng>[-+]?(?:180(?:\.0+)?|(?:1[0-7]\d|[1-9]?\d)(?:\.\d+)?))$/.exec(
                        await navigator.clipboard.readText()
                      );
                    if (coords === null) {
                      notification.info({
                        message: 'No valid coordinates found copied in your clipboard',
                      });
                      return;
                    }

                    const { lat, lng } = coords.groups as { lat: string; lng: string };
                    form.setFields([
                      {
                        name: 'latitude',
                        value: lat,
                        errors: [],
                      },
                      {
                        name: 'longitude',
                        value: lng,
                        errors: [],
                      },
                    ]);
                  } catch (error) {
                    if (error instanceof DOMException && error.name === 'NotAllowedError') {
                      notification.error({
                        message: 'You need to give Clipboard permission for this to work',
                      });
                    }
                  }
                }}
              >
                Paste coordinates from clipboard
              </Button>
            </Col>
          </Row>
        ) : null}
        <Form.Item name="notes" label="Notes">
          <Select disabled={!isUpdatingNotesAllowed} mode="tags" options={noteOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EventUpdateForm;
