import { LogEvent, PatchLogEventPayload } from 'interfaces';
import moment from 'moment-timezone';

export const isRestrictedEvent = (logEvent: LogEvent) => {
  return isInspectedEvent(logEvent) && moment().subtract(8, 'days').isBefore(moment(logEvent.eventTime.timestamp));
};

export const isInspectedEvent = (logEvent: LogEvent) => {
  return !!logEvent.i;
};

export const isCertification = (logEvent: LogEvent) => {
  return [
    // does not include certifications
    'DR_CERT_1',
    'DR_CERT_2',
    'DR_CERT_3',
    'DR_CERT_4',
    'DR_CERT_5',
    'DR_CERT_6',
    'DR_CERT_7',
    'DR_CERT_8',
    'DR_CERT_9',
  ].includes(logEvent.eventCode.id);
};

export const getLogEvent = (patchLogEvent: PatchLogEventPayload) => {
  const { originData, committedData } = patchLogEvent;
  return (committedData || originData) as unknown as LogEvent;
};
