import { FormOutlined, PlayCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Card, Col, Collapse, Layout, Menu, Row, Typography } from 'antd';
import type { FC } from 'react';
import { Link, Route, useLocation, useRouteMatch } from 'react-router-dom';

const Panel = Collapse.Panel;

const VideoCard = ({
  title,
  poster,
  videoSrc,
  description,
}: {
  title: string;
  poster: string;
  videoSrc: string;
  description: string;
}) => (
  <Card
    title={title}
    cover={
      <video
        controls
        controlsList="nodownload"
        onContextMenu={(e) => e.preventDefault()}
        preload="none"
        poster={poster}
      >
        <source src={videoSrc} type="video/webm" />
        Your browser does not support this video format.
      </video>
    }
  >
    <p>{description}</p>
  </Card>
);

const HelpPage: FC = () => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const currentSubRoute = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const videoData = [
    {
      title: 'Selector and Log chart area',
      poster: '/media/0.%20Selectors%20and%20Log%20chart%20area.jpg',
      videoSrc: '/media/0.%20Selectors%20and%20Log%20chart%20area.webm',
      description: 'Introduction to the controls for working with log events of a selected driver.',
    },
    {
      title: 'Status area',
      poster: '/media/1.%20Status%20area.jpg',
      videoSrc: '/media/1.%20Status%20area.webm',
      description: 'Introduction to the log (status) area.',
    },
    {
      title: 'Functional Buttons. Part 1',
      poster: '/media/2.%20Functional%20Buttons.%20Part%201.%20Move%20on.jpg',
      videoSrc: '/media/2.%20Functional%20Buttons.%20Part%201.%20Move%20on.webm',
      description: 'Introduction to the functions. Part 1.',
    },
    {
      title: 'Functional Buttons. Part 2',
      poster: '/media/3.%20Functional%20Buttons.%20Part%202.jpg',
      videoSrc: '/media/3.%20Functional%20Buttons.%20Part%202.webm',
      description: 'Introduction to the functions. Part 2.',
    },
    {
      title: 'Functional Buttons. Part 3',
      poster: '/media/4.%20Functional%20Buttons.%20Part%203.jpg',
      videoSrc: '/media/4.%20Functional%20Buttons.%20Part%203.webm',
      description: 'Introduction to the functions. Part 3.',
    },
    {
      title: 'Co-driver mode',
      poster: '/media/6.%20Co-driver%20mode%20(explanation%20video).jpg',
      videoSrc: '/media/6.%20Co-driver%20mode%20(explanation%20video).webm',
      description: 'Learn about Co-driver mode.',
    },
    {
      title: 'Distance between two events - prevent teleports',
      poster: '/media/7.%20Distance%20between%20two%20points.jpg',
      videoSrc: '/media/7.%20Distance%20between%20two%20points.webm',
      description:
        'Learn regarding the warning from the automatic log check about a substantial discrepancy in distance values between two events. This warning is crucial for preventing teleportation issues.',
    },
    {
      title: 'Warning about downloaded statuses - yellow edit button',
      poster: '/media/8.%20Downloaded%20status%20(Yellow%20edit%20button).jpg',
      videoSrc: '/media/8.%20Downloaded%20status%20(Yellow%20edit%20button).webm',
      description: 'Learn about warnings when editing downloaded statuses.',
    },
    {
      title: 'Adjust odometer value',
      poster: '/media/9.%20odometer.jpg',
      videoSrc: '/media/9.%20odometer.webm',
      description: 'Learn about easily adjusting odometer value on multiple events.',
    },
    {
      title: 'ALERTS page',
      poster: '/media/5.%20ALERTS%20function.jpg',
      videoSrc: '/media/5.%20ALERTS%20function.webm',
      description: 'Learn about adding a company or driver specific alert.',
    },
    {
      title: 'Certify function',
      poster: '/media/10.%20Certify%20function.jpg',
      videoSrc: '/media/10.%20Certify%20function.webm',
      description: 'Learn how to use the Certify function to generate certifications.',
    },
    {
      title: 'Force-refresh function',
      poster: '/media/11.%20Force-refresh%20function.jpg',
      videoSrc: '/media/11.%20Force-refresh%20function.webm',
      description:
        'Learn how to use the Force-refresh function to syncronize the data of companies and drivers with the portal.',
    },
  ];

  return (
    <Layout.Content style={{ padding: '50px', minHeight: 'calc(100vh - 64px)' }}>
      <Typography.Title level={3} style={{ textTransform: 'uppercase' }}>
        Help & Support
      </Typography.Title>
      <Layout style={{ background: 'white' }}>
        <Layout.Sider width={200} style={{ background: 'white' }}>
          <Menu
            mode="inline"
            theme="dark"
            style={{ height: '100%' }}
            defaultSelectedKeys={['tutorials']}
            selectedKeys={[currentSubRoute === path.slice(1) ? 'tutorials' : currentSubRoute]}
          >
            <Menu.Item key="tutorials" icon={<PlayCircleOutlined />}>
              <Link to={`${url}/tutorials`}>Videos & Tutorials</Link>
            </Menu.Item>
            <Menu.Item key="faq" icon={<QuestionCircleOutlined />}>
              <Link to={`${url}/faq`}>FAQ</Link>
            </Menu.Item>
            <Menu.Item key="feedback" icon={<FormOutlined />}>
              <Link to={`${url}/feedback`}>Feedback</Link>
            </Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout.Content style={{ padding: '12px 24px', minHeight: 280 }}>
          <Route
            exact
            path={[
              `${path}/`,
              `${path}/tutorials`,
            ]}
          >
            <Typography.Title level={4}>Videos & Tutorials</Typography.Title>
            <Row
              gutter={[
                16,
                16,
              ]}
            >
              {videoData.map((video, index) => (
                <Col key={index} span={8}>
                  <VideoCard {...video} />
                </Col>
              ))}
            </Row>
          </Route>
          <Route path={`${path}/faq`}>
            <Typography.Title level={4}>FAQ</Typography.Title>
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Как правильно пользоваться функцией Certify?" key="1">
                <p>
                  <ol>
                    <li>В логах не должно быть HOS нарушений.</li>
                    <li>Длительность статуса Sleeper или Off duty должна быть не менее 10 часов.</li>
                    <li>
                      Если время от Sleeper до Logout и от Login до On duty менее 3 секунд, то сертификация не будет
                      сгенерирована. Если время от Login до On duty менее 3 секунд, но On duty (PTI) более 15 минут, то
                      сертификации будут генерироваться на On duty.
                    </li>
                    <li>
                      Для успешной генерации сертификаций необходим минимальный интервал в одну минуту между статусами.
                    </li>
                    <li>
                      Для генерации сертификаций в дни Off duty нового водителя необходимо включить этот пустой Off duty
                      статус в загрузочный период (Load period). Следует отметить, что для новых водителей статус Off
                      duty автоматически создается за 14 дней до дня активации.
                    </li>
                    <li>
                      Кнопку сертифай стоит нажимать в самом конце до Recalculate Log (пересчета engine hours и ID.
                    </li>
                    <li>
                      Для корректной генерации сертификаций необходимо, чтобы загруженный период составлял не менее 9-ти
                      последних дней. Меньший период потребует ручной проверки, чтобы убедиться в отсутствии пропущенных
                      данных.
                    </li>
                    <li>
                      Ручное удаление сертификаций не требуется. Функция Certify автоматически удаляет все существующие
                      сертификации и создает новые для тех дней, которые могут быть сертифицированы на основе имеющихся
                      данных.
                    </li>
                    <li>
                      Signature file - это электронная подпись водителя, которая генерируется вместе со статусом
                      сертификации. При нажатии на "Certify" система запросит поставить или подтвердить уже существующий
                      signature file для того, чтобы начать процесс генерации сертификации.
                    </li>
                    <li>
                      После завершения генерации сертификаций система отображает всплывающее уведомление со списком дат,
                      для которых сертификации не были созданы. В случае, если для какой-либо даты сертификация
                      отсутствует, необходимо проверить выполнение всех условий, необходимых для её создания. Если все
                      условия соблюдены, но сертификация не была сгенерирована автоматически, её необходимо создать
                      вручную.
                    </li>
                  </ol>
                </p>
              </Panel>
              <Panel header="Для чего нужна кнопка CERTIFICATIONS?" key="2">
                <p>
                  Кнопка CERTIFICATIONS в левом нижнем углу отображает количество сертификаций в прогруженном периоде, а
                  также позволяет отмечать все сертификации в периоде.
                </p>
              </Panel>
              <Panel header="Для чего нужна кнопка Force refresh на странице ACTIVITY?" key="3">
                <p>
                  Если в списке компаний или водителей не отображается какая-либо компания или водитель, необходимо
                  нажать кнопку Force refresh. После этого водитель или компания, существующие в оптимайзере, появятся в
                  списке. Чаще всего эта кнопка нужна при активации новой компании и водителя, а также может
                  понадобиться при реактивации водителя.
                </p>
              </Panel>
              <Panel header="Как добавить нового пользователя?" key="4">
                <p>
                  Вы можете добавить нового пользователя из вкладки Users. Обратите внимание, что для доступа к вкладке
                  Users у вашей учетной записи должны быть права администратора.
                </p>
              </Panel>
              <Panel header="Как удалить или заблокировать пользователя?" key="5">
                <p>
                  Вы можете удалить или заблокировать пользователей во вкладке Users. Обратите внимание, что для доступа
                  к вкладке Users у вашей учетной записи должны быть права администратора.
                </p>
              </Panel>
            </Collapse>
          </Route>
          <Route path={`${path}/feedback`}>
            <Typography.Title level={4}>Feedback</Typography.Title>
            <iframe
              title="Feedback form"
              src="https://docs.google.com/forms/d/e/1FAIpQLSc3peuxYOh8dgxKCVNT5FHJ1qczJAn9fKneC0pfCJj6y-7CjQ/viewform?embedded=true"
              style={{ border: '0', width: '100%', minHeight: '680px' }}
            >
              Loading…
            </iframe>
          </Route>
        </Layout.Content>
      </Layout>
    </Layout.Content>
  );
};

export default HelpPage;
